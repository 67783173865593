// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "bootstrap"
import "jquery"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

const onClickSelector = function (selector, handler) {
    return function(event) {
        let el = event.target;
        do {
            if (el.matches(selector)) {
                handler.call(el, event);
            }
        } while ((el = el.parentNode) && el !== this);
    };
};

const init = () => {
    document.addEventListener('click', onClickSelector('.toggle-tweet-supress', function (event) {
        const id = this.getAttribute('data-id');
        fetch(`/products/${id}/toggle-tweet`,
            {method: 'post', headers: {
                    'X-CSRF-Token' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                }})
            .then();
    }))

};

init();
